<template>
  <div>
    <div class="d-flex justify-content-center flex-column mt-5">
      <b-row>
        <b-col md="6">
          <h2 class="h3">Création de votre radio</h2>
          <p>
            Nous allons à présent créer votre identifiant de connexion à
            <b>myHubcast</b> qui vous permettra de contrôler votre player.
            <br />
            Veuillez choisir votre mot de passe.
          </p>
          <b-row class="mt-3">
            <b-col cols="12">
              <h-input
                textLight
                :disabled="loginDisabled"
                label="Identifiant"
                :valueProp="login"
                v-model.trim="login"
                :errorState="$v.login.$error"
                error-feedback="L'identifiant est obligatoire pour la création de votre compte"
              ></h-input>
            </b-col>
            <b-col class="mt-3" cols="12">
              <h-input
                type="password"
                textLight
                label="Définir un mot de passe"
                v-model.trim="password"
                :errorState="$v.password.$error"
                :error-feedback="passwordFeedbackError()"
              ></h-input>
            </b-col>
            <b-col class="mt-3" cols="12">
              <h-input
                type="password"
                textLight
                label="Confirmation de mot de passe"
                v-model.trim="passwordConfirmation"
                :errorState="$v.passwordConfirmation.$error"
                :error-feedback="
                  !$v.passwordConfirmation.$model
                    ? 'Veuillez confirmer votre mot de passe'
                    : 'Les mots de passe ne sont pas identiques'
                "
              ></h-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6 mt-3 mt-md-0">
          <h2 class="h3">Votre abonnement</h2>
          <p>
            Vous disposez d'une période d'essai sans engagement et sans carte
            bancaire de
            <b>
              {{ campaign.trialDurationDays }} jour{{
                campaign.trialDurationDays > 1 ? 's.' : '.'
              }}
            </b>
            <br />
            Une fois la période d'essai achevée, vous pourrez vous abonner en
            souscrivant à l'offre préférentielle suivante :
          </p>
          <p>
            <span class="font-weight-semi-bold">
              Accès aux services myHubcast
            </span>
            <br />
            <span class="price"> {{ serviceFee }}€</span>
            /mois
          </p>
          <p v-if="locationFee && locationFee > 0">
            <span class="font-weight-semi-bold">
              Location du boitier
            </span>
            <br />
            <span class="price"> {{ locationFee }}€</span>
            /mois
          </p>
          <p v-if="facturationPeriod">Facturation {{ facturationPeriod }}</p>
        </b-col>
      </b-row>
    </div>

    <b-row class="mt-3">
      <b-col md="6">
        <h-button @click="prevStep()" grey outline size="md" class="my-3">
          <b-icon icon="arrow-left-short" scale="1.3" class="mr-1"></b-icon>
          Etape précédente
        </h-button>
      </b-col>
      <b-col md="6" class="d-flex justify-content-end">
        <h-button
          :disabled="loading"
          @click="createUser"
          green
          size="md"
          class="mt-md-3 mb-3"
        >
          <template v-if="loading">
            Création de votre compte...
          </template>
          <template v-else>
            <b-icon icon="check" scale="1.3" class="ml-1"></b-icon>
            Commencer mon essai gratuit
          </template>
        </h-button></b-col
      >
    </b-row>
  </div>
</template>

<script>
import campaignService from '@/services/campaignService'
import dateFormat from '@/components/dateFormat'
import { mapState } from 'vuex'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      login: '',
      password: '',
      passwordConfirmation: '',
      loading: false,
      availableLogin: '',
    }
  },
  validations: {
    login: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value)
        const containsLowercase = /[a-z]/.test(value)
        const containsNumber = /[0-9]/.test(value)
        return containsUppercase && containsLowercase && containsNumber
      },
    },
    passwordConfirmation: { required, sameAsPassword: sameAs('password') },
  },
  async created() {
    this.loginDisabled = true
    const loginProposition = await campaignService.createLogin(
      this.brandName,
      this.campaignSite.name
    )
    this.login = loginProposition.data
    this.loginDisabled = false
  },
  methods: {
    async createUser() {
      const userToCreate = {
        IdUser: -1,
        Login: this.login,
        password: this.password,
        Token: this.password,
        Email: this.campaignSite.email,
        FirstName: '',
        LastName: '',
      }

      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.loading = true
          const user = await campaignService.createUser(
            userToCreate,
            this.login,
            this.campaignSite.idSite
          )
          // eslint-disable-next-line no-console
          const logs = {
            login: this.login,
            password: this.password,
          }
          this.$store.commit('campaign/SET_CAMPAIGN_LOGS', logs)

          await this.editCampaignState(user.data)
          this.nextStep()
        } catch (e) {
          // eslint-disable-next-line no-console
          if (e.response && e.response.status && e.response.status === 409) {
            this.$toast.error(
              "Un compte existe déjà avec l'adresse email ou l'identifiant renseigné"
            )
          } else {
            this.$toast.error(
              "Une erreur s'est produite, veuillez réessayer ultérieurement"
            )
          }
        } finally {
          this.loading = false
        }
      }
    },
    async editCampaignState(idUser) {
      const params = {
        IdSubscriptionCampaign: this.campaign.id,
        SubscriptionDate: dateFormat.encodeServerDate(this.$moment()),
        IdBrand: this.brandId,
        IdSite: this.campaignSite.idSite,
        Owner: idUser,
        X3Infos: '',
        StripeInfos: '',
        CampState: 4,
      }
      const createArticles = false
      const campaignState = await campaignService.editCampaign(
        params,
        createArticles
      )
      // eslint-disable-next-line no-console
      console.log(campaignState)
    },
    prevStep() {
      this.$emit('prevStep')
    },
    nextStep() {
      this.$emit('nextStep')
    },
    passwordFeedbackError() {
      if (this.$v.password.$model === '')
        return 'Veuillez rensigner un mot de passe'
      else if (this.$v.password.$model.length < 8)
        return 'Votre mot de passe doit contenir au moins 8 caractères'
      else
        return 'Votre mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre'
    },
  },
  computed: {
    ...mapState({
      brandName: (state) => state.campaign.brand.name,
      brandId: (state) => state.campaign.brand.id,
      campaign: (state) => state.campaign.campaign,
      campaignSite: (state) => state.campaign.campaignSite,
    }),
    serviceFee() {
      const service = this.$store.state.campaign.campaign
        .subscriptionCampaignPaymentDetail.calculatedPrice
      return service
    },
    facturationPeriod() {
      const period = this.$store.state.campaign.campaign
        .subscriptionCampaignPaymentDetail.libPayment
      const facturationPeriods = {
        MENS: 'mensuelle',
        TRIM: 'trimestrielle',
        SEM: 'semestrielle',
        ANU: 'annuelle',
      }

      return facturationPeriods[period]
    },
    locationFee() {
      const location = this.$store.state.campaign.campaign.playerAmountHandfee
      if (location) {
        return location
      } else return null
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  width: 100%;
}
.price {
  color: $primary;
  font-size: 1.6rem;
  font-weight: 600;
}
.sup {
  vertical-align: super;
  font-size: 50%;
  font-weight: bold;
}

@media (min-width: 992px) {
  button {
    width: auto;
  }
}
</style>
